<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/empresas">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Financeiro</v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-row>
                    <v-col>
                        <v-card elevation="2" class="ma-1" flat>
                            <v-card-title class="text-center">{{ dados.fantasia }}</v-card-title>
                            <v-card-text>
                                <v-list v-if="dados.plan" subheader one-line dense>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-file-document-edit-outline</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Plano</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ dados.plan.plan_id }} - {{ dados.plan.name }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-currency-usd</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Valor</v-list-item-title>
                                            <v-list-item-subtitle>R$ {{ (dados.value / 100).toFixed(2) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-qrcode-scan</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Assinatura ID</v-list-item-title>
                                            <v-list-item-subtitle>{{ dados.subscription_id }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-cash-multiple</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Forma de Pagamento</v-list-item-title>
                                            <v-list-item-subtitle>{{ dados.payment_method }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-wifi-strength-outline</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Status</v-list-item-title>
                                            <v-list-item-subtitle>{{ translate(dados.status) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-calendar-clock</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Proxima cobrança</v-list-item-title>
                                            <v-list-item-subtitle>{{ formatDate(dados.next_execution, 'DD/MM/YYYY') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-calendar-multiple-check</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Proximo vencimento</v-list-item-title>
                                            <v-list-item-subtitle>{{ formatDate(dados.next_expire_at, 'DD/MM/YYYY') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card elevation="2" class="ma-1">
                            <v-card-title class="text-center">Histórico de cobranças</v-card-title>
                            <v-card-text>
                                <v-data-table
                                    fixed-header
                                    :headers="headers"
                                    :items="historico"
                                    sort-by="charge_id"
                                    :mobileBreakpoint="0"
                                    :loading="loading"
                                    :height="$vuetify.breakpoint.height - 260"
                                    @click:row="detalhes"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                >
                                    <template v-slot:item.created_at="{ item }">
                                        {{ formatDate(item.created_at, 'DD/MM/YYYY') }}
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        {{ translate(item.status) }}
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-menu v-if="['waiting', 'unpaid'].includes(item.status)" offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list v-if="checkPermission('zapercontrol_empresas_historico_cobrancas_marcar_pago')">
                                                <v-list-item @click.stop="marcarPago(item.charge_id)">
                                                    <v-list-item-title>
                                                        <v-icon>mdi-check</v-icon>&nbsp;Marcar como pago
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                            <v-list v-if="checkPermission('zapercontrol_empresas_historico_cobrancas_alterar_vencimento')">
                                                <v-list-item @click.stop="alterarVencimento(item)">
                                                    <v-list-item-title>
                                                        <v-icon>mdi-pencil</v-icon>&nbsp;Alterar vencimento
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <CobrancaDetalhes
                    v-model="cobranca"
                    :visible="visible1"
                    @close="close1"
                />
                <CobrancaAlterarVencimento
                    v-model="cobranca"
                    :visible="visible2"
                    @close="close2"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import CobrancaDetalhes from './CobrancaDetalhes';
import CobrancaAlterarVencimento from './CobrancaAlterarVencimento';
import { mapState } from 'vuex';

export default {
    name: 'Financeiro',

    components: {
        BaseContainer,
        CobrancaDetalhes,
        CobrancaAlterarVencimento,
    },

    data: () => ({
        loading: false,
        visible1: false,
        visible2: false,
        dados: {},
        cobranca: {},
        headers: [
            { text: 'ID', value: 'charge_id' },
            { text: 'Emissão', value: 'created_at' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ]
    }),

    computed: {
        ...mapState([
            'usuario',
        ]),

        baseZaperMenu() {
            return (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8082'
                : 'https://app.zapermenu.com.br';
        },

        baseZaperComandas() {
            return (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8081'
                : 'https://admin.zapermenu.com.br';
        },

        historico() {
            return this.dados.history || [];
        },
    },

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            if (!this.checkPermission('zapercontrol_empresas_historico_cobrancas_consultar')) {
                alert('Usuário sem permissão');
                return;
            }

            const empresa_id = this.$route.params.emp;

            this.loading = true;
            this.dados = {};

            this.$http.post('assinaturas/detalhes', { empresa_id }).then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        marcarPago(chargeId) {
            if (!confirm('Deseja realmente marcar como pago?')) {
                return;
            }

            const empresa_id = this.$route.params.emp;

            this.$root.$emit('loading', true);
            this.$http.post('assinaturas/marcar-pago', { chargeId, empresa_id }).then(resp => {
                const data = resp.data.data;
                if (data.type == 'warning') {
                    alert(data.msg);
                    return;
                }

                alert('Concluido com sucesso');
                this.consultar();
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },

        alterarVencimento(cobranca) {
            this.cobranca = cobranca;
            this.visible2 = true;
        },

        detalhes(cobranca) {
            if (!this.checkPermission('zapercontrol_empresas_cobranca_detalhes_consultar')) {
                return;
            }

            this.cobranca = cobranca;
            this.visible1 = true;
        },

        close1() {
            this.cobranca = {};
            this.visible1 = false;
        },

        close2() {
            this.cobranca = {};
            this.visible2 = false;
        },

        translate(s) {
            if (s == 'settled') return 'Pago manual';
            if (s == 'paid') return 'Pago';
            if (s == 'unpaid') return 'Vencido';
            if (s == 'new') return 'Novo';
            if (s == 'waiting') return 'Aguardando';
            if (s == 'canceled') return 'Cancelado';
            if (s == 'active') return 'Ativo';

            if (s == 'banking_billet') return 'Bolix';
            return s;
        },
    },
}
</script>
